#taskSubmit {
  margin-left: 20px;
}

#taskInput label {
  color: gold;
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: block;
}

td {
  text-align: left;
  font-size: 2rem;
  padding: 5px 0px 5px 20px;
}

.pi-trash {
  color: firebrick;
  cursor: pointer;
  font-size: 2rem;
}

table {
  width: 30vw;
  margin: auto;
  background-color: white;
  border-radius: 15px;
  margin-top: 25px;
}
