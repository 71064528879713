#wordleSubmit,
#wordleGuessSubmit {
  margin-left: 20px;
}

#loadScreen label,
#gameScreen label {
  color: gold;
  font-size: 1.5rem;
  margin-bottom: 20px;
  display: block;
}

.guessInput {
  display: block;
}

.guessRows {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* height: 150px; */

  max-width: 800px;
  margin: auto;
  margin-top: 15px;
}

.letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: gray;
  border-radius: 15px;
  font-size: 3rem;
}

.trueLetter {
  color: green;
}

.falseLetter {
  color: red;
}

.existsLetter {
  color: goldenrod;
}
